import Hero from "assets/img/investment/Hero V9.png";

import "./index.scss";

const investment = () => {
  return (
    <div className="investment_box">
      <img src={Hero} alt=""></img>
      <div className="investment_title_box">
        <div>
          <p>SInvestment Scope And Strategy</p>
        </div>
        <div>
          Super5 Group's investment business covers multiple fields, including
          but not limited to real estate, stocks, bonds, private equity funds,
          venture capital, etc.
        </div>
        {/* <div>Read More</div> */}
      </div>
    </div>
  );
};

export default investment;
