import { useState, useEffect } from "react";
import StyledDivider from "components/StyledDivider";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import group1 from "assets/img/group/group1.jpg";
import group2 from "assets/img/group/group2.jpg";
import group3 from "assets/img/group/group3.jpg";
import group4 from "assets/img/group/group4.jpg";
import group5 from "assets/img/group/group5.jpg";
import group6 from "assets/img/group/group6.jpg";
import group7 from "assets/img/group/group7.jpg";
import group8 from "assets/img/group/group8.jpg";
import group9 from "assets/img/group/group9.jpg";
import group10 from "assets/img/group/group10.jpg";
import "./index.scss";

const GroupBrands = () => {
  const navigate = useNavigate();

  const [slidesToShow, setSlidesToShow] = useState(
    window.innerWidth < 768 ? 3 : 4.3
  );

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(window.innerWidth < 768 ? 2 : 4.3);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="groupBrands_box">
      <StyledDivider text="SUPER 5 Group Brands" />
      <div className="groupBrands_content">
        The brands under the Super 5 Group grow together with the company
      </div>
      <Carousel autoplay slidesToShow={slidesToShow}>
        <img
          src={group1}
          alt="Group 1"
          onClick={() => handleClick("/transmission")}
        />
        <img
          src={group2}
          alt="Group 2"
          onClick={() => handleClick("/real-estate")}
        />
        <img
          src={group3}
          alt="Group 3"
          onClick={() => handleClick("/services")}
        />
        <img
          src={group4}
          alt="Group 4"
          onClick={() => handleClick("/lucky-5")}
        />
        <img
          src={group5}
          alt="Group 5"
          onClick={() => handleClick("/investments")}
        />
        <img
          src={group6}
          alt="Group 6"
          onClick={() => handleClick("/transmission")}
        />
        <img
          src={group7}
          alt="Group 7"
          onClick={() => handleClick("/real-estate")}
        />
        <img
          src={group8}
          alt="Group 8"
          onClick={() => handleClick("/services")}
        />
        <img
          src={group9}
          alt="Group 9"
          onClick={() => handleClick("/lucky-5")}
        />
        <img
          src={group10}
          alt="Group 10"
          onClick={() => handleClick("/investments")}
        />
      </Carousel>
    </div>
  );
};

export default GroupBrands;
