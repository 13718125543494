import "./index.scss";

const Banner = () => {
  return (
    <div className="project_banner">
      <p>Our Projects</p>
      <p>Real Estate &Automobile & Investment</p>
    </div>
  );
};

export default Banner;
