import Frame from "assets/img/autoCar/Frame (1).png";
import Frame1 from "assets/img/autoCar/Frame.png";
import img365 from "assets/img/autoCar/365.png";
import Rectangle from "assets/img/autoCar/Rectangle 2.png";
import col1 from "assets/img/autoCar/col-1.png";
import col2 from "assets/img/autoCar/col-2.png";
import col3 from "assets/img/autoCar/col-3.png";
// import Arrowrigth from "assets/img/car/Arrowrigth.png";
import "./index.scss";

const AutoCarBanner = () => {
  return (
    <>
      <div className="autoCar_banner">
        <p>AUTO CAR REPAIR service</p>
      </div>
      <div className="autoCar_content">
        <div className="title_box">repair gearbox</div>
        <div className="icon_box">
          <div>
            <img src={Frame1} alt=""></img>
            <p>professional</p>
          </div>
          <div className="line_box">
            <img src={Frame} alt=""></img>
            <p>fast</p>
          </div>
          <div>
            <img src={img365} alt=""></img>
            <p>warranty365</p>
          </div>
        </div>
        <div className="img_box">
          <img src={col1} alt=""></img>
          <img src={col2} alt=""></img>
          <img src={col3} alt=""></img>
        </div>
      </div>
      <div className="profess_box">
        <div className="text_box">
          <p>Professional • Fast • Warranty365</p>
          <p>
            Super 5 has various advanced high-tech imported testing and
            maintenance equipment, as well as over ten years of experience in
            automotive maintenance services. The pursuit goal of Super 5 is to
            create "first-class technology, first-class management, and
            first-class service", with "quality first, user first" as the
            service tenet of the enterprise.
          </p>
        </div>
        <div className="common_box">
          <div className="common_left">
            <img src={Rectangle} alt=""></img>
          </div>
          <div className="common_right">
            <p>common gearbox problems</p>
            <p>And Why They Occur</p>
            <p>
              Super 5 has various advanced high-tech imported testing and
              maintenance equipment, as well as over ten years of experience in
              automotive maintenance services.
            </p>
            {/* <button className="rent-button" onClick={handleContactScroll}>
              Contact Us
              <img src={Arrowrigth} alt="" className="button-arrow" />
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoCarBanner;
