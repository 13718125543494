import Home from "page/home";
import OurProject from "page/ourProject";
import Car from "page/car";
import Rental from "page/rental";
import Investment from "page/investment";
import RealEstate from "page/realEstate";
import AutoCar from "page/autoCar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/overview" element={<OurProject />} />
        <Route path="/services" element={<Car />} />
        <Route path="/lucky-5" element={<Rental />} />
        <Route path="/investments" element={<Investment />} />
        <Route path="/real-estate" element={<RealEstate />} />
        <Route path="/transmission" element={<AutoCar />} />
      </Routes>
    </Router>
  );
};

export default App;
