import React from "react";
import "./serviceCard.scss";

const ServiceCard = ({ number, title, description }) => (
  <div className="service-card">
    <div className="service-card-header">
      <div className="service-number">{number}</div>
      <div className="service-title">{title}</div>
      <div className="service-description">{description}</div>
    </div>
  </div>
);

export default ServiceCard;
