import image1 from "assets/img/rental/image.png";
import image2 from "assets/img/rental/image (1).png";
import image3 from "assets/img/rental/image (2).png";
import Replace from "assets/img/rental/Image (Replace).png";
import ServiceSection from "../../../components/InfoCard";
import "./index.scss";

const rentalData = [
  {
    title: "Home is wherever i’m with you",
    content:
      "High quality rental housing, enjoy the warmth and comfort of home!",
    imageUrl: image1,
  },
  {
    title: "One stop rental service",
    content:
      "Super5 provides one-stop rental services, including property search, viewing guide, contract signing, check-in  delivery, and other comprehensive services.Provide personalized and customized leasing solutions to meet the diverse needs and budgets of customers.",
    imageUrl: image2,
  },
  {
    title: "High  quality assurance",
    content:
      "Super5 has high standards for property quality and service quality, ensuring a comfortable living experience for customers.",
    imageUrl: image3,
  },
];

const RentalContent = () => (
  <div className="Rental_box">
    <div className="automotive-solutions">
      {rentalData.map((section, index) => (
        <ServiceSection
          key={index}
          title={section.title}
          content={section.content}
          imageUrl={section.imageUrl}
          isImageRight={index % 2 === 0}
          textClassName="text_width"
        />
      ))}
    </div>
    <div className="choice_box">
      <div className="choice_content">
        <div>Multiple Choice</div>
        <div>
          Located in the city center, with convenient transportation and a wide
          range of shopping, dining, and entertainment options. Modern
          decoration style provides you with a comfortable and convenient urban
          living experience.
        </div>
        <div>
          <img src={Replace} alt=""></img>
        </div>
      </div>
    </div>
  </div>
);

export default RentalContent;
