import { useState, useEffect, useRef } from "react";
import Header from "components/Header";
import { useLocation } from "react-router-dom";
import PhoneHeader from "components/PhoneHeader";
import Banner from "Sections/projects/banner";
import Business from "Sections/projects/business";
import Automotive from "Sections/projects/automotive";
import Financial from "Sections/projects/financial";
import LogosSection from "Sections/projects/logosSection";
import Footer from "components/Footer";

const OurProject = () => {
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const groupBrandsRef = useRef(null);
  const businessRef = useRef(null);
  const newsRef = useRef(null);
  const contactRef = useRef(null);

  const location = useLocation();
  const businessSectionRef = useRef(null);
  const automotiveSectionRef = useRef(null);
  const financialSectionRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1328);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1328);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  useEffect(() => {
    const sectionRefs = {
      businessSection: businessSectionRef,
      automotiveSection: automotiveSectionRef,
      financialSection: financialSectionRef,
    };

    const hash = location.hash.replace("#", "");
    if (hash && sectionRefs[hash] && sectionRefs[hash].current) {
      sectionRefs[hash].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div>
      <CurrentHeader
        homeRef={homeRef}
        aboutUsRef={aboutUsRef}
        groupBrandsRef={groupBrandsRef}
        businessRef={businessRef}
        newsRef={newsRef}
        contactRef={contactRef}
      />
      <Banner />
      <div ref={businessSectionRef}>
        <Business />
      </div>
      <div ref={automotiveSectionRef}>
        <Automotive />
      </div>
      <div ref={financialSectionRef}>
        <Financial />
      </div>
      <LogosSection />
      <Footer />
    </div>
  );
};

export default OurProject;
