import ServiceCard from "./serviceCard";
import "./index.scss";

const serviceData = [
  {
    number: "01",
    title: "Real Estate Engineering Construction",
    description:
      "Super5 Group has rich experience and a professional team in the field of real estate construction, and is committed to creating high-quality and unique construction projects. We pay attention to details to ensure that projects are completed on time and with quality.",
  },
  {
    number: "02",
    title: "Labor Services",
    description:
      "As an important part of real estate development, Super5 provides flexible and efficient labor services, including human resources management, construction personnel recruitment and training, etc., and continues to provide labor services to many real estate construction companies around the world.",
  },
  {
    number: "03",
    title: "Construction Machinery Rental",
    description:
      "Super5 has advanced construction machinery and equipment and provides various types of machinery rental services, including cranes, excavators, loaders, etc., to meet the needs of different projects.",
  },
  {
    number: "04",
    title: "Lndustrial Real Estate Development",
    description:
      "In addition to residential and commercial projects, Super5 also focuses on industrial real estate development, providing high-quality industrial parks and factories for manufacturing and logistics companies.",
  },
  {
    number: "05",
    title: "Real estate design services",
    description:
      "Super5 focuses on the innovation and practicality of real estate design. Super5 Design Company is headquartered in Tokyo, Japan, and provides design solutions that meet local standards and market needs for global users.",
  },
  {
    number: "06",
    title: "Vacation Home Operations",
    description:
      "Super5 is committed to creating unique and comfortable vacation rentals, providing a full range of vacation residence services for high-end users around the world.",
  },
  {
    number: "07",
    title: "Chilaoz Youth Apartment",
    description:
      "Super5 is committed to creating a comfortable, convenient and dynamic living environment for young people. it's here,You can unleash your youthful passion and realize your dreams in life.",
  },
  {
    number: "08",
    title: "Building materials import and export trading services",
    description:
      "As an important support for real estate construction, Super5 provides import and export trade services of building materials to ensure the construction quality and progress of the project.",
  },
];

const Business = () => {
  return (
    <div className="pro_business_box">
      <div className="business_title">super 5 real estate business</div>
      <div className="business_container">
        {serviceData.map((service) => (
          <ServiceCard
            key={service.number}
            number={service.number}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Business;
