import React from "react";
import ServiceSection from "../../../components/InfoCard";
import "./index.scss";
import auto1 from "assets/img/ourProject/auto1.png";
import auto2 from "assets/img/ourProject/auto2.png";
import auto3 from "assets/img/ourProject/auto3.png";
import auto4 from "assets/img/ourProject/auto4.png";

const automotiveData = [
  {
    title: "Manufacturing and Distribution of Automobile Transmission Parts",
    content:
      "The SUPER 5 Group possesses advanced production equipment and a skilled technical team in the field of automotive transmission parts manufacturing. Its product research and development center is located in Nagoya, Japan, while its production factory is situated in Dubai, United Arab Emirates. The company consistently delivers high-quality automotive transmission parts and distributes them globally, ensuring reliable product support for its customers.",
    imageUrl: auto1,
  },
  {
    title: "Automobiles and construction machinery",
    content:
      "SUPER 5's service centers are spread across the Middle Eastern market, providing efficient transmission repair services for top global luxury car brands.",
    imageUrl: auto2,
  },
  {
    title: "Business chauffeur service",
    content:
      "SUPER 5's commercial van rental service currently possesses over 100 Mercedes-Benz V-Class series commercial vans, each paired with a standard business driver. We offer reliable and cost-effective services for long-term Middle Eastern corporate clients.",
    imageUrl: auto3,
  },
  {
    title: "Import and Export Operations",
    content:
      "SUPER 5's import and export trade primarily provides commercial passenger vehicles and second-hand construction machinery import and export services to Middle Eastern customers.",
    imageUrl: auto4,
  },
];

const Automotive = () => (
  <div className="automotive-solutions">
    <h1>Automotive Solutions</h1>
    {automotiveData.map((section, index) => (
      <ServiceSection
        key={index}
        title={section.title}
        content={section.content}
        imageUrl={section.imageUrl}
        isImageRight={index % 2 === 0}
      />
    ))}
  </div>
);

export default Automotive;
