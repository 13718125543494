import { useState, useEffect } from "react";
import { Divider } from "antd";

const StyledDivider = ({ text }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {!isMobile && (
        <Divider style={{ borderColor: "#b7b7b7" }}>
          <p
            style={{
              margin: "0 10px",
              fontSize: "40px",
              color: "#4B4B4B",
              fontFamily: "PTSerif",
            }}
          >
            {text}
          </p>
        </Divider>
      )}
      {isMobile && (
        <p
          style={{
            fontSize: "36px",
            color: "#000000",
            textAlign: "left",
            fontWeight: "800",
          }}
        >
          {text}
        </p>
      )}
    </div>
  );
};

export default StyledDivider;
