import image1 from "assets/img/investment/image1.png";
import image2 from "assets/img/investment/Image2.png";
import Background from "assets/img/investment/Background.png";
import ServiceSection from "../../../components/InfoCard";
import "./index.scss";

const investmentData = [
  {
    title: "Investment Performance",
    content:
      "Super5 Group regularly evaluates and analyzes investment performance, including indicators such as return on investment, risk adjusted return on investment, and asset allocation efficiency.",
    imageUrl: image1,
  },
  {
    title: "Portfolio Management",
    content:
      "The investment portfolio management of Super5 Group includes aspects such as asset allocation, risk management, and return optimization. </br> Regularly evaluate and adjust investment portfolios based on market environment and investment objectives, optimize the structure and allocation of investment portfolios.",
    imageUrl: image2,
  },
];

const Content = () => (
  <>
    <div className="automotive-solutions content">
      {investmentData.map((section, index) => (
        <ServiceSection
          key={index}
          title={section.title}
          content={section.content}
          imageUrl={section.imageUrl}
          isImageRight={index % 2 === 0}
          textClassName="text_width"
        />
      ))}
    </div>
    <div className="last_box">
      <div className="img_left">
        <img src={Background} alt=""></img>
      </div>
      <div className="text_right">
        <div className="text_box">
          <p>Investment Decision Process</p>
          <p>
            The investment decision-making process of Super5 Group includes
            multiple stages such as project selection, due diligence, investment
            analysis, negotiation, decision-making, and execution.
          </p>
          <p>
            The investment decision-making process of Super5 Group includes
            multiple stages such as project selection, due diligence, investment
            analysis, negotiation, decision-making, and execution.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default Content;
