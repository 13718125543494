import { useState, useEffect, useRef } from "react";
import Header from "components/Header";
import PhoneHeader from "components/PhoneHeader";
import HomeBanner from "Sections/home/homeBanner";
import AboutUs from "Sections/home/aboutUs";
import GroupBrands from "Sections/home/groupBrands";
import Business from "Sections/home/business";
import Contact from "Sections/home/contact";
import News from "Sections/home/news";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";

const Home = () => {
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const groupBrandsRef = useRef(null);
  const businessRef = useRef(null);
  const newsRef = useRef(null);
  const contactRef = useRef(null);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1328);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1328);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      const refMap = {
        aboutUs: aboutUsRef,
        groupBrands: groupBrandsRef,
        business: businessRef,
        news: newsRef,
        contact: contactRef,
      };
      const ref = refMap[hash];
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div>
      <CurrentHeader
        homeRef={homeRef}
        aboutUsRef={aboutUsRef}
        groupBrandsRef={groupBrandsRef}
        businessRef={businessRef}
        newsRef={newsRef}
        contactRef={contactRef}
      />
      <div>
        <HomeBanner ref={homeRef} />
      </div>
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>
      <div ref={groupBrandsRef}>
        <GroupBrands />
      </div>
      <div ref={businessRef}>
        <Business />
      </div>
      <div ref={newsRef}>
        <News />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
