import logo from "assets/img/logo/logo.png";
import "./index.scss";

const Footer = () => {
  return (
    <div className="footer_box">
      <div className="footer_content">
        <img src={logo} alt=""></img>
      </div>
      <div>
        <p>Real Estate & Automobile & Investment</p>
        <p>
          Address: uper five building contracting llc, Bay Square office,
          building 06, Dubai Uae
        </p>
      </div>
      <div>
        <p>Contact number : +971526555555</p>
        <p>Copyright © 2024 SUPER5.co.kr All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
