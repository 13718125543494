import StyledDivider from "components/StyledDivider";
import business2 from "assets/img/business/business2.jpg";
import business1 from "assets/img/business/business1.jpg";
import business7 from "assets/img/business/business7.png";
import right from "assets/img/business/right.png";
import rightO from "assets/img/business/rightO.png";
import Group from "assets/img/business/Group 4906.png";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Business = () => {
  const navigate = useNavigate();

  const handleNavigate = (section) => {
    navigate(`/overview#${section}`);
  };
  return (
    <div className="business_box">
      <StyledDivider text={"Main Business"} />
      <div className="business_content ">
        <div className="business_details">
          <div className="business_title">
            <h1 style={{ textTransform: "uppercase" }}>
              REAL ESTATE ENTERPRISE
            </h1>
          </div>
          <ul className="business_list">
            <li>Real estate engineering construction</li>
            <li>Labor services</li>
            <li>Construction machinery rental</li>
            <li>Industrial real estate development</li>
            <li>Real estate design services</li>
            <li>Vacation Home Operations</li>
            <li>Youth Apartment</li>
            <li>Building materials import and export trading services</li>
          </ul>
          <button
            className="learn-more-button"
            onClick={() => handleNavigate("businessSection")}
          >
            <span className="text">Learn more</span>
            <img src={right} alt=""></img>
          </button>
        </div>
        <div className="business_images">
          <img src={business1} alt="Construction" />
          <img src={business2} alt="Worker" />
        </div>
      </div>
      <div className="business_content services">
        <div className="business_details services_width">
          <div className="business_title">
            <h1 style={{ textTransform: "uppercase" }}>Automotive solutions</h1>
            <h2>
              Manufacturing and Distribution of Automobile Transmission Parts
            </h2>
          </div>
          <ul className="business_list">
            <li>Automotive transmission services</li>
            <li>Business chauffeur service</li>
            <li>Automobiles and construction machinery</li>
            <li>Import and Export Operations</li>
          </ul>
          <button
            className="learn-more-button"
            onClick={() => handleNavigate("automotiveSection")}
          >
            <span className="text">Learn more</span>
            <img src={right} alt=""></img>
          </button>
        </div>
        <div className="business_group_images services_img">
          <img src={Group} alt="" />
        </div>
      </div>
      <div className="financial_Investment">
        <div className="financial_content">
          <h1>Financial Investment Solutions</h1>
          <p></p>
          <div className="description">
            We highly value every interaction with our partners and promptly
            extend assistance whenever needed. We eagerly anticipate cultivating
            strong investment relationships with even more esteemed partners.
          </div>
          <button
            className="learn-more-button financial_button"
            onClick={() => handleNavigate("financialSection")}
          >
            <span className="text">Learn more</span>
            <img src={rightO} alt=""></img>
          </button>
        </div>
        <div className="image">
          <img src={business7} alt="Financial Investment" />
        </div>
      </div>
    </div>
  );
};

export default Business;
