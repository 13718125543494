import React from "react";
import StyledDivider from "components/StyledDivider";
import logo from "assets/img/logo/logo.jpg";
import new1 from "assets/img/aboutUs/new1.png";
import country from "assets/img/aboutUs/country.png";
import "./index.scss";

const AboutUs = () => {
  const videoUrl = "https://assets.super5.co/videos%2F4835_1712663117.m4v";

  return (
    <div className="aboutUs_box">
      <StyledDivider text={"About Us"} />
      <div className="aboutUs_content">
        <img src={logo} alt="" className="aboutUs_logo"></img>
        <p>
          Established in 2005, SUPER 5 Group is a global enterprise
          headquartered in Dubai, United Arab Emirates, specializing in real
          estate development, automobile services, and financial investment.
          Renowned for its exceptional capabilities and seasoned professionals,
          SUPER 5 Group has expanded its reach by establishing branches in key
          markets including Saudi Arabia, Japan, South Korea, Georgia, Greece,
          Turkey, and beyond. Our unwavering commitment to excellence drives our
          success and innovation as we continue to thrive in diverse economic
          landscapes worldwide.
        </p>
      </div>
      <div className="aboutUs_video">
        <video src={videoUrl} controls poster={new1}></video>
      </div>
      <div className="aboutUs_img">
        <img src={country} alt="" className="aboutUs_country"></img>
      </div>
    </div>
  );
};
export default AboutUs;
