import home from "assets/img/home/home.jpg";
import "./index.scss";

const Home = () => {
  return (
    <div className="home_box">
      <img src={home} alt=""></img>
      <div className="home_title_box">
        <div className="home_title">
          <p>SUPER</p>
          <p>5</p>
          <p>GROUP</p>
        </div>
        <div className="home_sol">—— Your global partner</div>
      </div>
    </div>
  );
};

export default Home;
