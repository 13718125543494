import Frame from "assets/img/rental/Frame 166.png";

import "./index.scss";

const Rental = () => {
  return (
    <div className="rental_box">
      <img src={Frame} alt=""></img>
      <div className="rental_title_box">
        <div>
          <p>SUPER 5</p>
        </div>
        <div>Find the best home for you</div>
        <div>
          Our rental services not only allow you to rent a suitable house, but
          also provide thoughtful supporting facilities. Let you feel the warmth
          of home even in your busy life.
        </div>
      </div>
    </div>
  );
};

export default Rental;
