import React, { useState } from "react";
import { Button, Menu, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "assets/img/logo/logo 1.png";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const PhoneHeader = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    setVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const navigateAndScroll = (hash) => {
    navigate(`/#${hash}`);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleBusinessClick = (e) => {
    e.stopPropagation();
    navigateAndScroll("business");
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item onClick={() => navigateAndScroll("home")}>Home</Menu.Item>
      <Menu.Item onClick={() => navigateAndScroll("aboutUs")}>
        About Us
      </Menu.Item>
      <Menu.Item onClick={() => navigateAndScroll("groupBrands")}>
        Group Brands
      </Menu.Item>
      <Menu.SubMenu title={<span onClick={handleBusinessClick}>Business</span>}>
        <Menu.Item onClick={() => handleNavigate("/overview")}>
          Business Overview
        </Menu.Item>
        <Menu.Item onClick={() => handleNavigate("/services")}>
          Chauffeur Services
        </Menu.Item>
        <Menu.Item onClick={() => handleNavigate("/transmission")}>
          Transmission Repair
        </Menu.Item>
        <Menu.Item onClick={() => handleNavigate("/lucky-5")}>
          Real Estate-Lucky 5
        </Menu.Item>
        <Menu.Item onClick={() => handleNavigate("/real-estate")}>
          Real Estate Leasing
        </Menu.Item>
        <Menu.Item onClick={() => handleNavigate("/investments")}>
          Investments
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item onClick={() => navigateAndScroll("news")}>
        News & Updates
      </Menu.Item>
      <Menu.Item onClick={() => navigateAndScroll("contact")}>
        Contact Us
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 20px",
        backgroundColor: "#000",
      }}
    >
      <img src={logo} alt="logo" style={{ width: "100px", marginTop: "8px" }} />
      <Dropdown
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={["click"]}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "black",
            fontSize: "24px",
            marginTop: "-8px",
          }}
          icon={<MenuOutlined style={{ fontSize: "24px", color: "#fff" }} />}
        />
      </Dropdown>
    </div>
  );
};

export default PhoneHeader;
