import React from "react";
import "./index.scss";
import connect from "assets/img/ourProject/connect.png";

const Financial = () => {
  return (
    <div className="financial-investment">
      <img
        src={connect}
        alt="Financial Investment"
        className="background-image"
      />
      <div className="financial_content">
        <h1>Financial Investment Solutions</h1>
        <p>
          We highly value every interaction with our partners and promptly
          extend assistance whenever needed. We eagerly anticipate cultivating
          strong investment relationships with even more esteemed partners.
        </p>
      </div>
    </div>
  );
};

export default Financial;
