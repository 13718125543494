import React from "react";
import StyledDivider from "components/StyledDivider";
import new1 from "assets/img/new/new1.jpg";
import new2 from "assets/img/new/new2.jpg";
import new3 from "assets/img/new/new3.png";
import "./index.scss";

const News = () => {
  return (
    <div className="news_box">
      <StyledDivider text="News & Events" />
      <div className="news_content">
        <div>
          <img src={new1} alt=""></img>
          <p>Global Service Expertise</p>
          <p>
            SUPER 5 Group is dedicated to delivering professional and
            exceptional services to a global clientele. Our operations span
            multiple countries and regions, equipped with extensive
            international experience and resources to effectively address the
            diverse needs of customers worldwide.
          </p>
        </div>
        <div>
          <img src={new2} alt=""></img>
          <p>Enterprise Strength</p>
          <p>
            SUPER 5 Group possesses robust financial stability and extensive
            project experience, enabling us to handle a diverse range of
            large-scale projects. With a dedicated professional team and an
            advanced management model in place, we ensure efficient and
            high-quality project completion.
          </p>
        </div>
        <div>
          <img src={new3} alt=""></img>
          <p>Future Outlook</p>
          <p>
            SUPER 5 Group will persist in upholding the business principles of
            'innovation, quality, and integrity,' aiming to consistently enhance
            service standards and brand reputation. We are dedicated to emerging
            as a global leader in the enterprise sector, with a focus on
            delivering added value to our customers, employees, and society at
            large.
          </p>
        </div>
      </div>
    </div>
  );
};

export default News;
