import { useState, useEffect } from "react";
import { Carousel } from "antd";
import Rectangle1 from "assets/img/car/Rectangle1.png";
import Rectangle2 from "assets/img/car/Rectangle2.png";
import Rectangle3 from "assets/img/car/Rectangle3.png";
import "./index.scss";

const Services = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="service_box">
      <div className="title">Our Service</div>
      <div className="desc">
        <p>
          Our luxury chauffeur service aims to provide an unparalleled
          experience in transportation for our esteemed clientele.
        </p>
        <p>
          With a focus on comfort, safety, and personalized service, we strive
          to elevate every journey into a memorable and luxurious experience.
          Whether it's airport transfers,corporate travel, or special events,
          our service promises excellence at every turn.
        </p>
      </div>
      <div className="car_slides">
        {isMobile ? (
          <>
            <img src={Rectangle1} alt="Group 1" />
            <img src={Rectangle2} alt="Group 2" />
            <img src={Rectangle3} alt="Group 3" />
          </>
        ) : (
          <Carousel autoplay slidesToShow={1.7} centerMode={true}>
            <img src={Rectangle1} alt="Group 1" />
            <img src={Rectangle2} alt="Group 2" />
            <img src={Rectangle3} alt="Group 3" />
          </Carousel>
        )}
      </div>
    </div>
  );
};
export default Services;
