import { useState, useEffect, useRef } from "react";
import Header from "components/Header";
import PhoneHeader from "components/PhoneHeader";
import Banner from "Sections/investment/banner";
import InvestmentData from "Sections/investment/content";
import Footer from "components/Footer";

const Investment = () => {
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const groupBrandsRef = useRef(null);
  const businessRef = useRef(null);
  const newsRef = useRef(null);
  const contactRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1328);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1328);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  return (
    <div>
      <CurrentHeader
        homeRef={homeRef}
        aboutUsRef={aboutUsRef}
        groupBrandsRef={groupBrandsRef}
        businessRef={businessRef}
        newsRef={newsRef}
        contactRef={contactRef}
      />
      <Banner />
      <InvestmentData />
      <Footer />
    </div>
  );
};

export default Investment;
