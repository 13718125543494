import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "assets/img/ourProject/logo1.png";
import logo2 from "assets/img/ourProject/logo2.png";
import logo3 from "assets/img/ourProject/logo3.png";
import logo4 from "assets/img/ourProject/logo4.png";
import logo5 from "assets/img/ourProject/logo5.png";
import "./index.scss";

const logos = [
  { src: logo1, alt: "", path: "/services" },
  { src: logo2, alt: "", path: "/transmission" },
  { src: logo3, alt: "", path: "/lucky-5" },
  { src: logo4, alt: "", path: "/investments" },
  { src: logo5, alt: "", path: "/real-estate" },
];

const LogosSection = () => {
  const navigate = useNavigate();
  const [activeLogo, setActiveLogo] = useState(null);
  const handleLogoClick = (index) => {
    setActiveLogo(index);
    navigate(logos[index].path);
  };
  return (
    <div className="logos_section">
      <h2>Trusted By Top-Tier Teams Worldwide</h2>
      <div className="logos">
        {logos.map((logo, index) => (
          <div
            key={index}
            className={`logo ${activeLogo === index ? "active" : ""}`}
            onClick={() => handleLogoClick(index)}
          >
            <img src={logo.src} alt={logo.alt} />
            {activeLogo === index && <div className="active-line"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogosSection;
