import "./index.scss";

const RealEBanner = () => {
  return (
    <div className="realE_banner">
      <p>SUPER 5 REAL ESTATE ENTERPRISE</p>
      <p>
        Real estate engineering construction, labor services, construction
        machinery leasing, industrial real estate development, Real estate
        design services, vacation home operation,Chilaoz Apartment, Building
        materials import and export trading services
      </p>
    </div>
  );
};

export default RealEBanner;
