import React from "react";
import classNames from "classnames";
import "./index.scss";

const Section = ({
  title,
  content,
  imageUrl,
  isImageRight,
  textClassName,
  imgClassName,
}) => (
  <div
    className={classNames("service-section", { "image-right": isImageRight })}
  >
    <div className={classNames("text-content", textClassName)}>
      <p>{title}</p>
      {content.split("</br>").map((item, index) => (
        <React.Fragment key={index}>
          <p>{item}</p>
          {index < content.split("</br>").length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
    <div className={classNames("image-content", imgClassName)}>
      <img src={imageUrl} alt={title} />
    </div>
  </div>
);

export default Section;
