// PricingPage.jsx
import React from "react";
import price1 from "assets/img/car/price1.png";
import price2 from "assets/img/car/price2.png";
import Arrowrigth from "assets/img/car/Arrowrigth.png";
import Arrowrigthblack from "assets/img/car/Arrowrigthblack.png";
import WechatIMG30 from "assets/img/car/WechatIMG30.jpg";
import "./index.scss";

const PricingOption = ({
  title,
  prices,
  backgroundImage,
  detailsBackgroundColor,
  descriptionTextColor,
  buttonBackgroundColor,
  buttonTextColor,
  buttonBorder,
  buttonImage,
}) => {
  const handleButtonClick = () => {
    window.open(
      "https://api.whatsapp.com/message/EZPPLY3WADKOA1?autoload=1&app_absent=0",
      "_blank" // <- 这将在新标签页中打开链接
    );
  };

  return (
    <div className="pricing-option">
      <div
        className="pricing-header"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {title}
      </div>
      <ul
        className="pricing-details"
        style={{ backgroundColor: detailsBackgroundColor }}
      >
        {prices.map((price, index) => (
          <div className="details-line">
            <img src={WechatIMG30} alt="" className="dot" />
            <li key={index} className="pricing-item">
              <p
                className="price-description"
                style={{ color: descriptionTextColor }}
              >
                {price.description}
              </p>
              <p className="price">{price.price}</p>
            </li>
          </div>
        ))}
        <button
          className="rent-button"
          style={{
            backgroundColor: buttonBackgroundColor,
            color: buttonTextColor,
            border: buttonBorder,
          }}
          onClick={handleButtonClick}
        >
          Rent Payment
          <img src={buttonImage} alt="" className="button-arrow" />
        </button>
      </ul>
    </div>
  );
};

const PricingPage = () => {
  const ramadanOfferPrices = [
    { description: "10 hours 599 AED", price: "Full Day" },
    { description: "5 hours 299 AED", price: "Half Day" },
    { description: "Maximum 250km per day", price: "" },
    {
      description:
        "Inclusive of a professional chauffeur, fuel, and vehicle maintenance",
      price: "",
    },
  ];

  const regularPrices = [
    { description: "10 hours 799 AED", price: "Full Day" },
    { description: "5 hours 499 AED", price: "Half Day" },
    { description: "Maximum 250km per day", price: "" },
    {
      description:
        "Inclusive of a professional chauffeur, fuel, and vehicle maintenance",
      price: "",
    },
  ];

  return (
    <div className="pricing-page">
      <div className="pricing-page-title">Car Price</div>
      <div className="pricing-cards">
        <PricingOption
          title="Ramadan Offer Price"
          prices={ramadanOfferPrices}
          backgroundImage={price1}
          buttonImage={Arrowrigth}
          detailsBackgroundColor="#000"
          descriptionTextColor="#fff"
          buttonBackgroundColor="#ff6a00"
          buttonTextColor="#fff"
          buttonBorder="1px solid unset"
        />
        <PricingOption
          title="Regular Price"
          prices={regularPrices}
          backgroundImage={price2}
          buttonImage={Arrowrigthblack}
          detailsBackgroundColor="#fff"
          descriptionTextColor="#333"
          buttonBackgroundColor="#F8FAFF"
          buttonTextColor="#000"
          buttonBorder="1px solid #e1e4ed"
        />
      </div>
    </div>
  );
};

export default PricingPage;
