import React from "react";
import "./index.scss";

const CarModel = () => {
  return (
    <div className="car_model ">
      <div className="car_model_details">
        <div className="title">Car Model</div>
        <div className="car_model_card">
          <div className="model_left">
            <p className="one">Mercedes Benz V-CLASS</p>
            <p className="two">Model</p>
            <p className="three">Ivory or Black</p>
            <p className="four">Sheet color</p>
          </div>
          <div className="model_right">
            <p className="one">2023/24</p>
            <p className="two">Model year</p>
            <p className="three">6 Passenger, 5 bags</p>
            <p className="four">Sheet color</p>
          </div>
        </div>
      </div>
      <div className="car_model_image">
        {/* Image would be placed here as a background image in the SCSS */}
      </div>
    </div>
  );
};

export default CarModel;
