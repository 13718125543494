import "./index.scss";

const Contact = () => {
  return (
    <div className="contact_box">
      <h1>Engagement and Contact</h1>
      <p>
        We highly value every interaction with our partners and promptly extend
        assistance whenever needed. We eagerly anticipate cultivating strong
        investment relationships with even more esteemed partners.
      </p>

      <iframe
        title="Contact Us"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfOCTsiJvVNbhbA_AKJR8ffiPyp5oxMXWmdLqnyCRuf7rXrQA/viewform?embedded=true"
        width="640"
        height="420"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        className="responsive-iframe"
      >
        loading…
      </iframe>
    </div>
  );
};

export default Contact;
