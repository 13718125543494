import React from "react";
import logo from "assets/img/logo/logo.svg";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Header = () => {
  const navigate = useNavigate();

  const navigateAndScroll = (hash) => {
    navigate(`/#${hash}`);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleLogoClick = (path) => {
    navigate("/");
  };
  const content = (
    <div className="sec_page">
      <p onClick={() => handleNavigate("/overview")}>Business Overview</p>
      <p onClick={() => handleNavigate("/services")}>Chauffeur Services</p>
      <p onClick={() => handleNavigate("/transmission")}>Transmission Repair</p>
      <p onClick={() => handleNavigate("/lucky-5")}>Real Estate-Lucky 5</p>
      <p onClick={() => handleNavigate("/real-estate")}>Real Estate Leasing</p>
      <p onClick={() => handleNavigate("/investments")}>Investments</p>
    </div>
  );
  return (
    <div className="header_box">
      <div className="right_logo">
        <img src={logo} alt="logo" onClick={handleLogoClick} />
      </div>
      <div className="right_box">
        <div className="right_items" onClick={() => navigateAndScroll("home")}>
          Home
        </div>
        <div
          className="right_items"
          onClick={() => navigateAndScroll("aboutUs")}
        >
          About Us
        </div>
        <div
          className="left_items"
          onClick={() => navigateAndScroll("groupBrands")}
        >
          Group Brands
        </div>
        <Popover placement="bottom" content={content}>
          <div
            className="right_items"
            onClick={() => navigateAndScroll("business")}
          >
            Business
          </div>
        </Popover>
        <div className="right_items" onClick={() => navigateAndScroll("news")}>
          News & Updates
        </div>
        <div
          className="right_items"
          onClick={() => navigateAndScroll("contact")}
        >
          Contact Us
        </div>
      </div>
    </div>
  );
};

export default Header;
